import elliptical from "../references/elliptical";
import container from '../dependencies/container';
import {progress} from '../modules/ui';
import keys from '../references/keys';

var PAGE_SIZE=keys.PAGE_SIZE;
var Product=container.getType('Product');
var ProductSearch=container.getType('Product/Search/');
var ProductShowroom=container.getType('Product/Showroom/');
var ProductClearance=container.getType('Product/Clearance');

export default class Controller extends elliptical.Controller {
    async SubCategory(req, res, next) {
        let pageSize=PAGE_SIZE;
        let id = req.params.id;
        let category = req.params.category;
        let subcategory = req.params.subcategory;
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/' + category + '/' + subcategory + '/' + id;
        let rawUrl = req.url;
        let query=req.query;
        if(query) UTILITY.fireEvent('search-filters-query',{type:'SubCategory',id:id,url:rawUrl,query:query});
        progress.start();
        let product=new Product();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(query)
                .getAsync({id});
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count};
            res.render(context);
        } catch (err) {
            next(err);
        }
    }

    async Search(req, res, next) {
        let pageSize=PAGE_SIZE;
        let id = req.params.id;
        let category = req.params.category;
        let subcategory = req.params.subcategory;
        let term=encodeURIComponent(id);
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/Search/' + category + '/' + subcategory + '/' + id;
        let rawUrl = req.url;
        let query=req.query;
        if(query) UTILITY.fireEvent('search-filters-query',{type:'Search',id:id,url:rawUrl,query:query});
        progress.start();
        let product=new ProductSearch();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(query)
                .getAsync({id:term});
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count};
            res.render(context);
        } catch (err) {
            next(err);
        }
    }

    async Showroom(req, res, next) {
        let pageSize=PAGE_SIZE;
        let id = req.params.id;
        let category = req.params.category;
        let subcategory = req.params.subcategory;
        let term=encodeURIComponent(id);
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/Showroom/' + category + '/' + subcategory + '/' + id;
        let rawUrl = req.url;
        let query=req.query;
        if(query) UTILITY.fireEvent('search-filters-query',{type:'Showroom',id:id,url:rawUrl,query:query});
        progress.start();
        let product=new ProductShowroom();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(query)
                .getAsync({id:term});
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count};
            res.render(context);
        } catch (err) {
            next(err);
        }
    }

    async Clearance(req, res, next) {
        let pageSize=PAGE_SIZE;
        let id = req.params.id;
        let category = req.params.category;
        let subcategory = req.params.subcategory;
        let term=encodeURIComponent(id);
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/Clearance/' + category + '/' + subcategory + '/1';
        let rawUrl = req.url;
        let query=req.query;
        if(query) UTILITY.fireEvent('search-filters-query',{type:'Clearance',id:id,url:rawUrl,query:query});
        progress.start();
        let product=new ProductClearance();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(query)
                .getAsync();
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count};
            res.render(context);
        } catch (err) {
            next(err);
        }
    }

    async Promotion(req, res, next) {
        let pageSize=PAGE_SIZE;
        let id = req.params.id;
        let term=encodeURIComponent(id);
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/Promotion/' + id;
        let rawUrl = req.url;
        let query=req.query;
        if(query) UTILITY.fireEvent('search-filters-query',{type:'Promotion',id:id,url:rawUrl,query:query});
        progress.start();
        let product=new ProductClearance();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(query)
                .getAsync();
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count};
            res.render(context);
        } catch (err) {
            next(err);
        }
    }
}
    






