import elliptical from "../references/elliptical";
import container from '../dependencies/container';
import {progress} from '../modules/ui';

var Product=container.getType('PromotionProduct');

export default class Controller extends elliptical.Controller {
   async Index(req, res, next) {
       let pageSize=500;
       let id = req.params.id;
       let page = 1;
       if (req.query.page) page = parseInt(req.query.page);
       let baseUrl = '/Promotion/' + id;
       let rawUrl = req.url;
       let query=req.query;
       if(query) UTILITY.fireEvent('search-filters-query',{type:'Promotion',id:id,url:rawUrl,query:query});
       progress.start();
       let product=new Product();
       try {
           let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
               .filter(query)
               .getAsync({id});
           let items = result.data;
           let pagination = result.pagination;
           let count = pagination.count;
           let context = {items, pagination, count};
           res.render(context);
       } catch (err) {
           next(err);
       }
   }
}
    






