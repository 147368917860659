
import elliptical from '../references/elliptical';

var container=elliptical.container;
var Service = elliptical.Service;
var Location = elliptical.Location;
var $Cookie = elliptical.$Cookie;
var Sort = elliptical.Sort;
var $Sort = elliptical.$Sort;
var DomEvent = elliptical.DomEvent;
var $Rest = elliptical.$Rest;
var $Pagination = elliptical.$Pagination;
var $Linq=elliptical.$Linq;

var apiProtocol=location.protocol.replace(':', '');
var apiHost=location.hostname;
var apiPort=location.port;
var apiPath='/api';

if(apiProtocol==='https'){
    apiPort=443;
}
//set Rest endpoint props
$Rest.protocol = apiProtocol;
$Rest.host = apiHost;
$Rest.path = apiPath;
$Rest.port = apiPort;

var $rest = new $Rest();
$rest.$queryProvider=$Linq;

//asp.net OData prop settings for pagination
Service.$paginationProvider = $Pagination;
Service.$paginationProvider.count = 'count';
Service.$paginationProvider.data = 'items';
Service.$paginationProvider.pageQueryString = true;

//registrations
container.mapType('Service', Service, $rest);
container.mapType('Sort', Sort, $Sort);
container.mapType('Notify', elliptical.Notify, elliptical.$Notify);
container.registerType('$Rest', $Rest);
container.registerType('$rest', $rest);
container.registerType('Location', Location);
container.registerType('$Local', elliptical.$Local);
container.registerType('$Cookie', $Cookie);
container.registerType('$Linq', $Linq);
container.registerType('DomEvent', DomEvent);
container.registerType('$ViewData', elliptical.$ViewData);


export default container;
