
import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('search-filters', function (node) {
    var COOKIE_KEY='searchFilters';
    var COOKIE_KEY_PARAMS='searchParams';
    var ID=node.dataset.id;
    var BASE_URL=node.dataset.baseUrl;
    var TYPE=node.dataset.type;
    var FILTERED=node.dataset.filtered;
    var CLEARANCE=node.dataset.clearance;
    var Location=container.getType('Location');
    var $Cookie = container.getType('$Cookie');
    var $Linq = container.getType('$Linq');
    var DomEvent = container.getType('DomEvent');
    var dom = new DomEvent(node, this);
    dom.event(document,'search-filters-change', onFiltersChanged);
    dom.event(document,'search-filters-reset', onFiltersReset);
    dom.event(document,'search-filters-query', onQuery);

    function onInit(){
        if(FILTERED !=='True'){
            initCookieReference(ID,TYPE,BASE_URL);
        }
    }
    
    function onFiltersChanged(event){
        var url=parseFilters();
        if(url!=='') search(url);
        else onFiltersReset();
    }

    function onFiltersReset(event){
        resetCookieReference();
         Location.href=BASE_URL;
    }

    function onQuery(event){
        var data=event.detail;
        var query=data.query;
        if(query){
           var result=$Linq._getFilterString(query);
           setCookieReference(data.id,data.type,data.url,result);
        }
    }

    function parseFilters() {
        var filterAdded = 0;
        var url='';
        var styleId = getStyleFilter();
        if (styleId !== 0) {
            url += 'sql_Styles=' + encodeURIComponent('Styles.Any(Id==@0)@@' + styleId);
            filterAdded += 1;
        }
        var types = getTypeFilters();
        var index = 1;
        if (types.length > 0) {
            types.forEach(function (id) {
                if (filterAdded > 0) url += '&';
                url += 'sql_' + id + '=' + encodeURIComponent('Filters.Any(Id==@0)@@' + id);
                filterAdded += 1;
            });
        }
        var showRoom=getShowroomFilter();
        if(showRoom !=='show_all'){
            if (filterAdded > 0) url += '&';
            url += 'sql_ShowRooms=' + encodeURIComponent('ShowRooms.Any(Name==@0)@@' + showRoom);
            filterAdded += 1;
        }
        var priceFilter=getPriceFilter();
        if(priceFilter.id>0){
            if (filterAdded > 0) url += '&';
            if(CLEARANCE =='True') url=getClearancePriceFilterUrl(url,priceFilter);
            else url=getMSRPPriceFilterUrl(url,priceFilter);
        }
        return url;
    }

    function getMSRPPriceFilterUrl(url,priceFilter){
        if(priceFilter.price){
            url += 'sql_Msrp=' + encodeURIComponent('(Msrp>@0)@@' + priceFilter.price);
        }else if(priceFilter.underPrice){
            url += 'sql_Msrp=' + encodeURIComponent('(Msrp<@0)@@' + priceFilter.underPrice);
        }else{
            url += 'sql_Msrp1=' + encodeURIComponent('(Msrp>@0)@@' + priceFilter.price1);
            url += '&sql_Msrp2=' + encodeURIComponent('(Msrp<@0)@@' + priceFilter.price2);
        }
        return url;
    }

    function getClearancePriceFilterUrl(url,priceFilter){
        if(priceFilter.price){
            url += 'sql_Price=' + encodeURIComponent('(Price>@0)@@' + priceFilter.price);
        }else if(priceFilter.underPrice){
            url += 'sql_Price=' + encodeURIComponent('(Price<@0)@@' + priceFilter.underPrice);
        }else{
            url += 'sql_Price=' + encodeURIComponent('(Price>@0)@@' + priceFilter.price1);
            url += '&sql_Price2=' + encodeURIComponent('(Price<@0)@@' + priceFilter.price2);
        }
        return url;
    }

    function getStyleFilter() {
        var styleRadioGroup = dom.find('[filter-id="styles"]');
        var item = styleRadioGroup[0].selectedItem;
        return parseInt(item.dataset.id);
    }

    function getShowroomFilter(){
        var styleRadioGroup = dom.find('[filter-id="showroom"]');
        var item = styleRadioGroup[0].selectedItem;
        return item.getAttribute('name');
    }

    function getTypeFilters() {
        var typeFilteRadioGroups = dom.find('[type-id]');
        var resultsArray = [];
        $.each(typeFilteRadioGroups, function (index,rad) {
            var item = rad.selectedItem;
            var id = parseInt(item.dataset.id);
            if (id !== 0) {
                resultsArray.push(id);
            }
        });
        return resultsArray;
    }

    function getPriceFilter(){
        var priceRadioGroup = dom.find('[filter-id="price"]');
        var item = priceRadioGroup[0].selectedItem;
        
        return{
           id:item.dataset.id,
           price1:item.dataset.price1,
           price2:item.dataset.price2,
           price:item.dataset.price,
           underPrice:item.dataset.underPrice
        };
    }

    function search(url) {
        var searchUrl=BASE_URL + '?' + url;
        Location.href = searchUrl;
    }

    function resetCookieReference(){
        $Cookie.delete(COOKIE_KEY);
        $Cookie.delete(COOKIE_KEY_PARAMS);
    }

    function setCookieReference(id,type,url,filters){
         var entity={id,type,url};
         $Cookie.set(COOKIE_KEY,filters);
         $Cookie.set(COOKIE_KEY_PARAMS,entity);
    }

    function initCookieReference(id,type,url){
        var entity={id,type,url};
        $Cookie.set(COOKIE_KEY_PARAMS,entity);
        $Cookie.delete(COOKIE_KEY);
    }

    onInit();

    this.dispose = ()=> {
        dom.unbind();
    };

});
